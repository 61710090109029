import React, { useContext, useState } from 'react';
import styles from './CarInspection.module.css';
import CountUp from 'react-countup';
import InputCommon from '../Commoninput';
import { ModalContext } from '..';

const ValuationSection = ({valuationPrice, vehicleType, scores}) => {

  // Filter out 'OTHER DETAILS'
const filteredScores = scores?.filter(item => item?.name !== 'OTHER DETAILS');

// Calculate the average score
const totalScore = filteredScores?.reduce((acc, item) => acc + parseFloat(item.score), 0);
const averageScore = totalScore / filteredScores?.length;

const [priceEdit, setPriceEdit] = useState(false);
const [priceValue, setPriceValue] = useState(valuationPrice);

const {
 editAccess
} = useContext(ModalContext);

  return (
    <div className={styles.valuationSection}>
      <div className={styles.valuationContent}>
        <div className={styles.valuationPriceColumn}>
          <div className={styles.valuationPriceWrapper}>
            <div className={styles.valuationPriceLabel}>
              <img src="/img/report/valuation-price.svg" alt="Valuation" className={styles.icon} />
              <span className={styles.labelText}>VALUATION PRICE</span>
            </div>
            <div onClick={()=>setPriceEdit(true)} className={styles.valuationPrice}>
            ₹&nbsp;
            {priceEdit && editAccess ? (
             <InputCommon
             body = {{finalPrice: Number(priceValue)}}
             editSection = {setPriceEdit}
             valueStore = {setPriceValue}
             value = {priceValue}
             editMode = "inventoryEdit"
             />
            ) : (
<CountUp style={{borderBottom: "1px dotted #2b2c2d", cursor: "pointer"}} end={valuationPrice} duration={2.5} />
            )}
            
              </div>
          </div>
        </div>
        <div className={styles.carScoreColumn}>
          <div className={styles.carScoreWrapper}>
            <div className={styles.carScoreLabel}>
              <img src="/img/report/car-score.svg" alt="Car Score" className={styles.carScoreIcon} />
              <span className={styles.labelText}>{vehicleType} SCORE</span>
            </div>
            <div className={styles.carScore}><CountUp end={Math.round(averageScore)} duration={3} />/10</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValuationSection;