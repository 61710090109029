import { Card, Form, Input, message, Modal, Spin, Upload } from "antd";
import Flex from "components/shared-components/Flex";
import UploadButton from "components/shared-components/UploadButton/UploadButton";
import React, { useContext, useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import UploadFileService from "services/UploadFileService";
import { ModalContext } from "..";
import InventoryService from "services/InventoryService";

function VideoModal({
  overlayVisible,
  setOverlayVisible,
  item,
  vehicleArray,
  videoLinks,
  interiorLink,
  exteriorLink
}) {
  const {
    hovered,
    setHovered,
    editType,
    setEditType,
    handleMouseEnter,
    handleMouseLeave,
    showEditModal,
    setShowEditModal,
    editText,
    setEditText,
    EditOutlined,
    handleTextSubmit,
    popconfirmVisible,
    setPopconfirmVisible,
    id,
    getData,
  } = useContext(ModalContext);

  const [images, setImages] = useState([
    {
      uid: 0,
      name: "image.png",
      status: "done",
      url: item?.image,
    },
  ]);

  const [videolink, setVideolink] = useState(item.link);
  const [uploading, setUploading] = useState(false);
  const [newVideo, setNewVideo] = useState(null)

  //videos upload

  const [videos, setVideos] = useState([
    {
      uid: '-1',
      name: 'video.mp4', // Adjust the name as needed
      status: 'done',
      url: item.link,
    }
  ]);


  const handleOk = async () => {
    const loadingMessage = message.loading(`updating...`, 0);
    setOverlayVisible(false);

    const body = {
      inspectionVideo: {
        interior: interiorLink,
        exterior: exteriorLink
      },
    };

    const values = await form.validateFields();
    console.log("new values are", images);

    body.inspectionVideo[item.name] = newVideo;

    const response = await InventoryService.putAdminInspection(id, body);
    console.log("final response", response);
    if (response) {
      getData();
      loadingMessage();
      message.success("updated Successfully");
    }

    console.log("final body is", body);
  };

  const handleCancel = () => {
    setOverlayVisible(false);
  };

  //image handle

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewVideo, setPreviewVideo] = useState("");

  console.log("file list", images);

  const handlePreview = async (file) => {
    console.log("preview file", file);
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewVideo(file.url || file.preview);
    setPreviewVisible(true);
  };

  const uploadButton = (
    <div>
      {uploading ? <Spin /> : <PlusOutlined />}
      <div className="ant-upload-text">{uploading ? 'Uploading' : 'Upload'}</div>
    </div>
  );

  const handleChange = ({ fileList }) => {
    setVideos(fileList.slice(-1));
  };

  const handleCancelVideo = () => setPreviewVisible(false);

  //antd form

  const [form] = Form.useForm();

  console.log("videos are", videos);

  return (
    <Modal
      title={item.name}
      visible={overlayVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Card title="Video">
        <Upload
          name="videos"
          listType="picture-card"
          accept="video/mp4, video/mkv, video/avi, video/mov, video/wmv"
          fileList={videos}
          onPreview={handlePreview}
          onChange={handleChange}
          beforeUpload={async (file) => {
            setUploading(true)
            try {
              console.log("file to upload", file);
              const formData = new FormData();
      formData.append("file", file);
      formData.append("videoFor", "inventory");
      const files = await UploadFileService.postVideo(formData);
      setNewVideo(files.data)
      setUploading(false)
              // const awsLink = await UploadFileService.postVideo(file);
              // file.url = awsLink;
              return false; // Prevent default upload behavior
            } catch (error) {
              return false; // Prevent default upload behavior if there's an error
            }
          }}
        >
          {uploadButton}
        </Upload>
        <Modal visible={previewVisible} footer={null} onCancel={handleCancelVideo}>
            <video style={{ width: '100%' }} controls>
              <source src={previewVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Modal>
      </Card>
    </Modal>
  );
}

export default VideoModal;
