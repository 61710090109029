import React, { useContext, useState } from "react";
import styles from "./DetailRow.module.css";
import { Input, Select } from "antd";
import { ModalContext } from "..";

const DetailRow = ({ label, value, inventoryData }) => {

  return (
    <div className={styles.row}>
      <div data-aos="fade-right" className={styles.label}>
        {label}
      </div>

      <div data-aos="fade-right" className={styles.value}>
        {value}
      </div>
    </div>
  );
};

export default DetailRow;
