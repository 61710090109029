import { Input } from "antd";
import React, { useContext } from "react";
import { ModalContext } from ".";

function InputCommon({ body, editSection, valueStore, value, editMode }) {

    const {
        hovered,
        setHovered,
        editType,
        setEditType,
        handleMouseEnter,
        handleMouseLeave,
        showEditModal,
        setShowEditModal,
        editText,
        setEditText,
        EditOutlined,
        handleTextSubmit,
        popconfirmVisible,
        setPopconfirmVisible,
      } = useContext(ModalContext);

  return (
    <Input
      onPressEnter={() => handleTextSubmit(body, editMode)}
      onBlur={() => editSection(false)}
      onChange={(e) => valueStore(e.target.value)}
      type="text"
      value={value}
    />
  );
}

export default InputCommon;
