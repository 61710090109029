import React from 'react';
import styles from './QAComponent.module.css';

const ConditionColumn = ({ title }) => {
  const conditions = [
    { label: 'Excellent', color: '#09a541' },
    { label: 'Good', color: '#ffe500' },
    { label: 'Average', color: '#f2ad00' },
    { label: 'Bad', color: '#f01e29' },
  ];

  return (
    <div data-aos="flip-left" data-aos-duration="1000" className={styles.column}>
      <div className={styles.columnContent}>
        <h3 className={styles.columnTitle}>{title}</h3>
        {conditions.map((condition, index) => (
          <div key={index} className={styles.conditionItem}>
            <span>{condition.label}</span>
            <div
              className={styles.colorIndicator}
              style={{ backgroundColor: condition.color }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConditionColumn;