import React, { useContext, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./CarInspection.module.css";
import useWindowWidth from "components/useWindowWidth";
import { saveAs } from "file-saver";
import { Modal } from "antd";
import ImageModal from "./ImageModal";
import { over } from "lodash";
import { ModalContext } from "..";

const ImageGallery = ({ imageArray, updatedAt }) => {
  const sliderRef = useRef(null);
  const {
    editAccess
   } = useContext(ModalContext);

  const width = useWindowWidth();

  const date = new Date(updatedAt);
 

  const options = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const vehicleImagesArray =
    imageArray &&
    Object.entries(imageArray).map(([key, value]) => ({
      title: key,
      image: value.image,
      info: value.info,
      latitude: value.latitude,
      longitude: value.longitude,
    }));


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const data = ["a", "b"];

  const images = [
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/d1227fb817ed5fe8a7015f8d118facdfac4f21f739c86c2739e60e0352814e72?apiKey=4046fc8bd3874965a4b9d4e7624dfda6&",
      title: "Right Side Right Edge",
      date: "Nov 12, 2023,04:42pm",
      gps: "GPS- 10.138323, 76.310661",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/857dcc881e6c703dbe4e3d1a4dce7f132be4a26af6943919d6f39505a555eab5?apiKey=4046fc8bd3874965a4b9d4e7624dfda6&",
      title: "EXTERIORS",
      date: "Nov 12, 2023,04:42pm",
      gps: "GPS- 10.138323, 76.310661",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/d1227fb817ed5fe8a7015f8d118facdfac4f21f739c86c2739e60e0352814e72?apiKey=4046fc8bd3874965a4b9d4e7624dfda6&",
      title: "Right Side Right Edge",
      date: "Nov 12, 2023,04:42pm",
      gps: "GPS- 10.138323, 76.310661",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/857dcc881e6c703dbe4e3d1a4dce7f132be4a26af6943919d6f39505a555eab5?apiKey=4046fc8bd3874965a4b9d4e7624dfda6&",
      title: "EXTERIORS",
      date: "Nov 12, 2023,04:42pm",
      gps: "GPS- 10.138323, 76.310661",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/d1227fb817ed5fe8a7015f8d118facdfac4f21f739c86c2739e60e0352814e72?apiKey=4046fc8bd3874965a4b9d4e7624dfda6&",
      title: "Right Side Right Edge",
      date: "Nov 12, 2023,04:42pm",
      gps: "GPS- 10.138323, 76.310661",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/857dcc881e6c703dbe4e3d1a4dce7f132be4a26af6943919d6f39505a555eab5?apiKey=4046fc8bd3874965a4b9d4e7624dfda6&",
      title: "EXTERIORS",
      date: "Nov 12, 2023,04:42pm",
      gps: "GPS- 10.138323, 76.310661",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/d1227fb817ed5fe8a7015f8d118facdfac4f21f739c86c2739e60e0352814e72?apiKey=4046fc8bd3874965a4b9d4e7624dfda6&",
      title: "Right Side Right Edge",
      date: "Nov 12, 2023,04:42pm",
      gps: "GPS- 10.138323, 76.310661",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/857dcc881e6c703dbe4e3d1a4dce7f132be4a26af6943919d6f39505a555eab5?apiKey=4046fc8bd3874965a4b9d4e7624dfda6&",
      title: "EXTERIORS",
      date: "Nov 12, 2023,04:42pm",
      gps: "GPS- 10.138323, 76.310661",
    },
  ];

  const handleNextArrowClick = (originalOnClick) => {
    console.log("clicked");
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const handlePreviousArrowClick = (originalOnClick) => {
    console.log("clicked");
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
      console.log("value is", sliderRef.current);
    }
  };

  const handleDownload = async (url) => {
    //   // Create an anchor element
    //   const link = document.createElement('a');
    //   link.href = url;
    //   link.download = 'image.jpg'; // The name for the downloaded file
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);

    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank"; // Open the link in a new tab
    link.download = "image.jpg"; // The name for the downloaded file

    // Append the link to the document body and click it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // try {
    //   const response = await fetch(url, {
    //     method: 'GET',
    //     headers: {
    //       'Content-Type': 'application/octet-stream',
    //     },
    //   });

    //   if (!response.ok) {
    //     throw new Error('Network response was not ok');
    //   }

    //   const blob = await response.blob();
    //   saveAs(blob, 'image.jpg');
    // } catch (error) {
    //   console.error('Error downloading file:', error);
    // }
  };

  const [overlayVisible, setOverlayVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  return (
    <div className="image-gallery">
      <div className={styles.imageGallery}>
        <Slider ref={sliderRef} {...settings}>
          {/* {images.map((image, index) => ( */}
          {vehicleImagesArray &&
            vehicleImagesArray?.map((image, index) => (
              <div key={index} className={styles.imageCard}>
                <div className={styles.imageInclude}>
                  {image.title == selectedImage && (
                    <ImageModal
                      item={image}
                      overlayVisible={overlayVisible}
                      setOverlayVisible={setOverlayVisible}
                      vehicleArray = {vehicleImagesArray}
                    />
                  )}
                  {editAccess && (
                  <div className={styles.overlayEdit}>
                    <p
                      className={styles.editButton}
                      onClick={() => {
                        setSelectedImage(image.title);
                        setOverlayVisible(true);
                      }}
                    >
                      Edit
                    </p>
                    {/* <p className={styles.removeButton}>Remove</p> */}
                  </div>
                  )}
                  <img
                    src={image.image}
                    alt="sdsd"
                    className={styles.carImage}
                  />
                </div>
                <div className={styles.imageInfo}>
                  <div className={styles.imageTitle}>{image.title}</div>
                  <div className={styles.approvalIcon}>
                    <img
                      onClick={() => handleDownload(image.image)}
                      src={
                        width > 480
                          ? "/img/report/slider-download.svg"
                          : "/report-img/mobile-download.svg"
                      }
                      alt="Approved"
                      className={styles.checkIcon}
                    />
                  </div>
                </div>
                <div className={styles.imageMetadata}>
                  <img
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/73d439d1173ba08d2820c1de79ef47f0ed2d41f85e45cf0c508680a80687c6b1?apiKey=4046fc8bd3874965a4b9d4e7624dfda6&"
                    alt="Date"
                    className={styles.metadataIcon}
                  />
                  <span className={styles.metadataText}>
                    {date.toLocaleString("en-US", options).replace(",", "")}
                  </span>
                </div>
                <div className={styles.imageMetadata}>
                  <img
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/7c820608ec84133b3115ea81b9c79772c7d37308773f056329bccac72d5f0d28?apiKey=4046fc8bd3874965a4b9d4e7624dfda6&"
                    alt="GPS"
                    className={styles.metadataIcon}
                  />
                  <span className={styles.metadataText}>
                    GPS-{image.longitude},{image.latitude}
                  </span>
                </div>
              </div>
            ))}
        </Slider>
        {vehicleImagesArray && (
        <div className={styles.sliderArrows}>
          <img
            onClick={handlePreviousArrowClick}
            className={styles.leftArrow}
            src="/report-img/left-arrow.svg"
          />
          <img
            onClick={handleNextArrowClick}
            className={styles.leftArrow}
            src="/report-img/right-arrow.svg"
          />
        </div>
        )}
      </div>
    </div>
  );
};

export default ImageGallery;
