import React from 'react';
import styles from './QAComponent.module.css';
import RatingColumn from './RatingColumn';
import ConditionColumn from './ConditionColumn';
import { Link, Element, animateScroll as scroll } from 'react-scroll';

const QAComponent = () => {
  return (
    <Element name="q/a">
    <div className={styles.container}>
      <h2 data-aos="fade-right" className={styles.title}>Q/A</h2>
      <div className={styles.content}>
        <div className={styles.columns}>
          <RatingColumn  />
          <ConditionColumn title="Vehicle Condition" />
          <ConditionColumn title="Engine Condition" />
        </div>
      </div>
    </div>
    </Element>
  );
};

export default QAComponent;