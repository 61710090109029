import fetch from 'auth/FetchInterceptor';

const ColorService = {};

const api = 'color';

ColorService.getAll = function (params) {
  return fetch({
    url: `${api}/fetch`,
    method: 'GET',
    params
  });
};

ColorService.get = function (id) {
  return fetch({
    url: `${api}/${id}`,
    method: 'GET'
  });
};

ColorService.post = function (data) {
  return fetch({
    url: `${api}`,
    method: 'POST',
    data: data
  });
};

ColorService.put = function (id, data) {
  return fetch({
    url: `${api}/${id}`,
    method: 'PUT',
    data: data
  });
};

export default ColorService;
