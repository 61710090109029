import React from "react";
import ReportItem from "./ReportItem";
import styles from "./styles/CarReport.module.css";

const reportItems = [
  {
    type: "Tyres",
    rating: 8,
    review: "Excellent",
    img: "/report-img/tyres-icon.svg",
  },
  {
    type: "Engine and Transmission",
    rating: 7,
    review: "Good",
    img: "/report-img/engine-icon.svg",
  },
  {
    type: "Interior",
    rating: 2,
    review: "Bad",
    img: "/report-img/interior-icon.svg",
  },
  {
    type: "Exterior",
    rating: 2,
    review: "Bad",
    img: "/report-img/exterior-icon.svg",
  },
  {
    type: "Body and frame",
    rating: 2,
    review: "Average",
    img: "/report-img/body-icon.svg",
  },
  {
    type: "Documents",
    rating: 5,
    review: "Average",
    img: "/report-img/document-icon.svg",
  },
];

const ReportGrid = ({ categoriesArray, scores }) => {

  return (
    <div className={styles.reportGrid}>
      <div className={styles.reportRow}>
        {scores
          ?.filter((item) => item.name !== "OTHER DETAILS")
          ?.map((item, index) => (
            <ReportItem key={index} {...item} />
          ))}
      </div>
    </div>
  );
};

export default ReportGrid;
