import React from 'react';
import styles from './DisclaimerContent.module.css';

const DisclaimerContent = () => {
  return (
    <article className={styles.disclaimerContent}>
      1.This report is generated by JVMPL :- A divison of Jai and Jai Vehicle Mall Pvt.,Ltd.,(JVMPL) at the only demand of the party whom this report is addressed and is to be used exclusively by the said party for specified purpose only. JVMPL will not be held liable for any loss or liability sustained by any third party relying on the valuation report. JVMPL has relied on the odometer reading at the time of inspection/valuation and is not responsible for verifying the genuineness thereof.<br />
      2.As there is no regular price list for pre-owned/used vehicles. The vehicle valuation indicated in the report is our opinion on the market value of the vehicle at the time of our inspection/valuation based on our specified valuation policy and procedures. Actual realization may differ from the valuation indicated the report and JVMPL (Including its director and employees) will not be held liable for any direct, indirect, consequential or exemplary damages for any loss resulting from use of this report. JVMPL is not responsible for verifying the realness of the title documents of the vehicle and its professional opinion on valuation of the car is primarily based on the condition of the vehicle at the time of inspection/valuation.
    </article>
  );
};

export default DisclaimerContent;