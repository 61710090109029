import React from 'react';
import styles from './VehicleSummary.module.css';
import VehicleDetails from './VehicleDetails';
import ApprovalSection from './ApprovalSection';

const VehicleSummary = ({remark, inventoryData}) => {
  return (
    <div className={styles.div}>
      <div className={styles.div2}>
        <div className={styles.column}>
          <VehicleDetails remark={remark} inventoryData = {inventoryData} />
        </div>
        <div className={styles.column2}>
          <ApprovalSection />
        </div>
      </div>
    </div>
  );
};

export default VehicleSummary;