import React, { useContext, useState } from 'react';
import styles from './CarInspection.module.css';
import { ModalContext } from '..';
import InputCommon from '../Commoninput';

const VehicleDetails = ({details, customer}) => {

  const {
    hovered,
    setHovered,
    editType,
    setEditType,
    handleMouseEnter,
    handleMouseLeave,
    showEditModal,
    setShowEditModal,
    editText,
    setEditText,
    EditOutlined,
    handleTextSubmit,
    popconfirmVisible,
    setPopconfirmVisible,
    editAccess
  } = useContext(ModalContext);

  const [registrationValue, setRegistrationValue] = useState(details?.registrationNumber);
  const [regEdit, setRegEdit] = useState(false);
  const [engineEdit, setEngineEdit] = useState(false)
  const [ engineValue, setEngineValue] = useState(details?.engineNumber)
  const [chassisEdit, setChassisEdit] = useState(false)
  const [chassisValue, setChassisValue] = useState(details?.chassisNumber)
  const [customerEdit, setcustomerEdit] = useState(false)
  const [customerValue, setcustomerValue] = useState(customer?.name)
  

  return (
    <div className={styles.vehicleDetails}>
      <div data-aos="fade-down" data-aos-duration="500" className={styles.detailsContent}>
        <div className={styles.detailsColumn}>
          <div className={styles.detailsList}>
            <div className={styles.detailsItem}>Registration No :</div>
            <div className={styles.detailsValue} onClick={()=>setRegEdit(true)}>
              {regEdit && editAccess ? (
                <InputCommon
                body = {{vehicleDetail: {...details,registrationNumber: registrationValue}}}
                editSection = {setRegEdit}
                valueStore = {setRegistrationValue}
                value = {registrationValue}
                editMode = "inspectionEdit"
                />
            ) : (<span className={styles.detSpan}>{details?.registrationNumber ? details?.registrationNumber : "N/A"}</span>)}
              </div>
            <div className={styles.detailsItem}>Engine No : </div>
            <div onClick={()=>setEngineEdit(true)} className={styles.detailsValue}>{engineEdit && editAccess ? (
                <InputCommon
                body = {{vehicleDetail: {...details,engineNumber: engineValue}}}
                editSection = {setEngineEdit}
                valueStore = {setEngineValue}
                value = {engineValue}
                editMode = "inspectionEdit"
                />
            ) : ( <span className={styles.detSpan}>{details?.engineNumber ? details?.engineNumber : "N/A"}</span> )}</div>
          </div>
        </div>
        <div className={styles.detailsColumn}>
          <div className={styles.detailsList}>
            <div className={styles.detailsItem}>Customer Name :</div>
            <div className={styles.detailsValue} onClick={()=>setcustomerEdit(true)}>
            {customerEdit && editAccess ? (
                <InputCommon
                body = {{customer: {...customer,name: customerValue}}}
                editSection = {setcustomerEdit}
                valueStore = {setcustomerValue}
                value = {customerValue}
                editMode = "inventoryEdit"
                />
            ) : (<span className={styles.detSpan}>{customer?.name ? customer?.name : "N/A"}</span>)}
              
              </div>
            <div className={styles.detailsItem}>Chassis No :</div>
            <div className={styles.detailsValue} onClick={()=>setChassisEdit(true)}>
            {chassisEdit && editAccess ? (
                <InputCommon
                body = {{vehicleDetail: {...details,chassisNumber: chassisValue}}}
                editSection = {setChassisEdit}
                valueStore = {setChassisValue}
                value = {chassisValue}
                editMode = "inspectionEdit"
                />
            ) : ( <span className={styles.detSpan}>{details?.chassisNumber ? details?.chassisNumber : "N/A"}</span> )}
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleDetails;