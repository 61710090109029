import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./VehicleInspection.module.css";
import { ModalContext } from "..";
import { Button, Input, Popconfirm, Select } from "antd";
import PopupComponent from "../PopupComponent";
import VehicleTypeService from "services/VehicleType";

const Header = ({ vehicleType, reportId, loanNumber }) => {
  const {
    hovered,
    setHovered,
    editType,
    setEditType,
    handleMouseEnter,
    handleMouseLeave,
    showEditModal,
    setShowEditModal,
    editText,
    setEditText,
    EditOutlined,
    handleTextSubmit,
    popconfirmVisible,
    setPopconfirmVisible,
    editAccess
  } = useContext(ModalContext);
  console.log("show edit modal is", popconfirmVisible);

  const [loanNo, setLoanNo] = useState(loanNumber);
  const [vehicleTypeValue, setVehicleTypeValue] = useState(vehicleType);
  const [loanWidth, setLoanWidth] = useState();
  const [loanEdit, setLoanEdit] = useState(false);
  const loanRef = useRef(null);
  const [vehicleEdit, setVehicleEdit] = useState(false);
  const [vehicleTypeData, setVehicleTypeData] = useState([]);

  const { Option } = Select;

  const handleConfirm = () => {
    // Handle confirm action
    console.log("Confirmed");
  };

  const handleCancel = () => {
    // Handle cancel action
    console.log("Cancelled");
  };

  const fetchvehicleType = async () => {
    const response = await VehicleTypeService.getAll();
    setVehicleTypeData(response.data);
  };


  useEffect(() => {
    if (vehicleEdit) {
      fetchvehicleType();
    }
  }, [vehicleEdit]);

  return (
    <div className={styles.header}>
      <div
        // onMouseEnter={() => handleMouseEnter("vehicleType", "text")}
        // onMouseLeave={handleMouseLeave}
        className={styles.title}
      >
        {vehicleEdit && editAccess ? (
          // <Input
          // onBlur = {()=>setVehicleEdit(false)}
          //   onPressEnter={() =>
          //     handleTextSubmit("vehicleType", "inventoryEdit", loanNo)
          //   }
          //   onChange={(e) => setVehicleTypeValue(e.target.value)}
          //   type="text"
          //   value={vehicleTypeValue}
          // />
          <Select
            onBlur={() => setVehicleEdit(false)}
            placeholder="Select an option"
            defaultValue = {vehicleType?._id}
            className={styles.vehicleData}
            onChange={(value)=>handleTextSubmit({vehicleTypeId: value},'inventoryEdit')}
          >
            {vehicleTypeData?.map((item) => (
              <Option value={item._id}>{item.name}</Option>
            ))}
          </Select>
        ) : (
          <span className={styles.vehSpan} onClick={() => setVehicleEdit(!vehicleEdit)}>
            {vehicleType?.name}
          </span>
        )}
        Vehicle INSPECTION REPORT
      </div>

      <div className={styles.reportId}>REPORT ID: {reportId}</div>
      <div
        // onMouseEnter={() => handleMouseEnter("loanNumber", "text")}
        // onMouseLeave={handleMouseLeave}
        className={styles.loanNo}
      >
        {/* LOAN NO: {loanNumber} */}
        <p>LOAN No :&nbsp;</p>
        {loanEdit && editAccess ? (
          <Input
            onPressEnter={() =>
              handleTextSubmit({loanNumber: loanNo}, "inventoryEdit")
            }
            onBlur={() => setLoanEdit(false)}
            onChange={(e) => setLoanNo(e.target.value)}
            type="text"
            value={loanNo}
          />
        ) : (
          <span
          className={styles.loanSpan}
            onClick={() => {
              if (loanRef.current) {
                console.log("loan ref is", loanRef.current);
                loanRef.current.focus(); // Focus the input when the button is clicked
              }
              setLoanEdit(!loanEdit);
            }}
          >
            {loanNo}
          </span>
        )}
      </div>
      {/* <span ref={loanRef} style={{ visibility: "hidden", whiteSpace: "pre", padding: "20px" }}>
        {loanNo}
      </span> */}
    </div>
  );
};

export default Header;
