import fetch from 'auth/FetchInterceptor';

const InventoryQuestionService = {};

const api = 'inventory_question';

InventoryQuestionService.getAll = function (params) {
  return fetch({
    url: `${api}/fetch`,
    method: 'GET',
    params
  });
};

InventoryQuestionService.get = function (id) {
  return fetch({
    url: `${api}/${id}`,
    method: 'GET'
  });
};

InventoryQuestionService.post = function (data) {
  return fetch({
    url: `${api}`,
    method: 'POST',
    data: data
  });
};

InventoryQuestionService.put = function (id, data) {
  return fetch({
    url: `${api}/${id}`,
    method: 'PUT',
    data: data
  });
};

InventoryQuestionService.delete = function (id) {
  return fetch({
    url: `${api}/${id}`,
    method: 'DELETE'
  });
};

InventoryQuestionService.generateInventoryQuestionReport = function (params) {
  return fetch({
    url: `${api}/generate`,
    method: 'GET',
    params
  });
};
export default InventoryQuestionService;
