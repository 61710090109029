import { Input, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ModalContext } from ".";
import { values } from "lodash";

function InputSelect({
  body,
  editSection,
  valueStore,
  value,
  editMode,
  data,
  setColorChange,
  colorChange,
  defaultOption
}) {
  const {
    hovered,
    setHovered,
    editType,
    setEditType,
    handleMouseEnter,
    handleMouseLeave,
    showEditModal,
    setShowEditModal,
    editText,
    setEditText,
    EditOutlined,
    handleTextSubmit,
    popconfirmVisible,
    setPopconfirmVisible,
  } = useContext(ModalContext);

  const { Option } = Select;
  const [flag, setFlag] = useState(false);

  const selectChange = async (value) => {
    await valueStore(value);
    setFlag(true);
  };

  useEffect(() => {
    if (flag) {
      console.log('select body is', body)
      handleTextSubmit(body, editMode);
      setFlag(false);
      if(setColorChange){
      setColorChange(!colorChange)
      }
    }
  }, [flag]);

  return (
    // <Input
    //   onPressEnter={() => handleTextSubmit(body, editMode)}
    //   onBlur={() => editSection(false)}
    //   onChange={(e) => valueStore(e.target.value)}
    //   type="text"
    //   value={value}
    // />

    <Select
      onBlur={() => {
        setFlag(false);
        editSection(false);
      }}
      onChange={selectChange}
      className="common-select"
      placeholder="Select an option"
      defaultValue = {defaultOption ? defaultOption : null}
    >
      {data.map((item) => (
        <Option value={item._id}>{item.name}</Option>
      ))}
    </Select>
  );
}

export default InputSelect;
