import React from 'react';
import styles from './QAComponent.module.css';

const RatingColumn = () => {
  return (
    <div className={styles.column}>
      <div data-aos="flip-left" data-aos-duration="1000" className={styles.columnContent}>
        <h3  className={styles.columnTitle}>Rating</h3>
        <div className={styles.ratingItem}>9 to 10</div>
        <div className={styles.ratingItem}>7 to 8</div>
        <div className={styles.ratingItem}>4 to 6</div>
        <div className={styles.ratingItem}>1 to 3</div>
      </div>
    </div>
  );
};

export default RatingColumn;