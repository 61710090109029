import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

const UploadButton = ({ loading = false }) => {
  return (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );
};

export default UploadButton;
