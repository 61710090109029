import React, { useContext, useState } from "react";
import styles from "./VehicleSummary.module.css";
import { Input } from "antd";
import { ModalContext } from "..";

const VehicleDetails = ({ remark, inventoryData }) => {
  const [summaryEdit, setSummaryEdit] = useState(false);
  const [summaryValue, setSummaryValue] = useState(inventoryData?.remark);

  

  const {
    hovered,
    setHovered,
    editType,
    setEditType,
    handleMouseEnter,
    handleMouseLeave,
    showEditModal,
    setShowEditModal,
    editText,
    setEditText,
    EditOutlined,
    handleTextSubmit,
    popconfirmVisible,
    setPopconfirmVisible,
    editAccess
  } = useContext(ModalContext);

  const handlePressEnter = () => {
    console.log('new remark',summaryValue)
    handleTextSubmit({remark : summaryValue},'inspectionEdit')
  }

  return (
    <div className={styles.div3}>
      <div className={styles.div4}>
        <div
          data-aos="fade-right"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          className={styles.div5}
        >
          <img
            loading="lazy"
            src="/img/report/vehicle-summary.svg"
            className={styles.img}
          />
          <div className={styles.div6}>VEHICLE SUMMARY : </div>
        </div>
        <div className={styles.div7} onClick={() => setSummaryEdit(true)}>
          {summaryEdit && editAccess ? (
            <Input.TextArea
              onBlur={() => setSummaryEdit(false)}
              onChange={(e) => setSummaryValue(e.target.value)}
              onPressEnter={()=>handlePressEnter(remark)}
              defaultValue={summaryValue}
            />
          ) : <span className={styles.remarkSpan}>{remark ? remark : "N/A"}</span> }
        </div>
      </div>
    </div>
  );
};

export default VehicleDetails;
