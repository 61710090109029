import React, { useState } from "react";
import styles from "./NavItem.module.css";
import { Link, Element, animateScroll as scroll } from "react-scroll";

const NavItem = ({ label, isActive, to, scrollTo, currentSection, setCurrentSection }) => {
   


    // const handleSetActive = (section) => {
    //     console.log('section value', section)
    //     setCurrentSection(section);
    //   };

  return (
    <Link to={scrollTo} offset={-200}  >
      <div
        onClick={()=>setCurrentSection(label)}
        className={`${styles.navItem} ${
          currentSection == label ? styles.active : ""
        }`}
      >
        {label}
      </div>
    </Link>
  );
};

export default NavItem;
