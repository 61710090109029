import React, {
  createContext,
  useEffect,
  useRef,
  useState,
  useStatecreateContext,
} from "react";
import VehicleInspection from "./vehicle-inspection/VehicleInspection";
import CarInspection from "./car-inspection/CarInspection";
import CarReport from "./car-report/CarReport";
import VehicleSummary from "./vehicle-summary/VehicleSummary";
import VahanDetails from "./vahan-details/VahanDetails";
import InteriorComponent from "./interior-components/InteriorComponent";
import QAComponent from "./qa-component/QAComponent";
import DisclaimerSection from "./disclaimer/DisclaimerSection";
import {
  bodyData,
  electricalData,
  engineData,
  exteriorData,
  interiorData,
  otherData,
} from "./InspectionData";
import Navbar from "./navbar/Navbar";
import useWindowWidth from "components/useWindowWidth";
import InventoryService from "services/InventoryService";
import { useLocation, useParams } from "react-router-dom";
import { message, Spin } from "antd";
import { API_BASE_URL } from "configs/AppConfig";
import { useSelector } from "react-redux";
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  UploadOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
} from "@ant-design/icons";
import PopConfirmBox from "./PopConfirmBox";

export const ModalContext = createContext();

function InspectionReport() {
  const width = useWindowWidth();
  const [isLoading, setIsLoading] = useState(false);
  const [inventoryData, setInventoryData] = useState([]);
  const [flag, setFlag] = useState(0);
  const token = useSelector((state) => state.auth.token);
  const [editAccess, setEditAccess] = useState(false);
  const hoverTimeout = useRef(null);
  const [editType, setEditType] = useState("");
  const [editText, setEditText] = useState("");
  const [hovered, setHovered] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [popconfirmVisible, setPopconfirmVisible] = useState(false);
  const [apiCall, setApiCall] = useState(false);

  useEffect(() => {
    if (token) {
      setEditAccess(true);
    } else {
      setEditAccess(false);
    }
  }, [token]);

  console.log("edit access", editAccess);

  const location = useLocation();
  const pathname = location.pathname;

  // Split the path and get the last part which is the ID
  const pathSegments = pathname.split("/");
  const id = pathSegments[pathSegments.length - 1];

  //categories finding

  // Set to store unique category names
  const uniqueCategories = new Set();

  // Extract unique categories
  inventoryData?.inspectionDetails?.forEach((item) => {
    if (item?.question?.category?.name !== "OTHER DETAILS") {
      uniqueCategories.add(item?.question?.category?.name);
    }
  });

  // Convert set to array for easier rendering
  const categoriesArray = Array.from(uniqueCategories);

  // const getData = async () => {
  //   setIsLoading(true);
  //   try {
  //     const inventoryData = await InventoryService.get(id);
  //     if (inventoryData) {
  //       const data = inventoryData.data;
  //       setInventoryData(inventoryData.data);
  //       console.log("final data is", data);
  //     }
  //   } catch (err) {
  //     console.log(err, "err");
  //   }
  //   setIsLoading(false);
  // };

  const getData = async () => {
    if (flag == 0) {
      setIsLoading(true);
    }
    //  setInventoryData([])
    try {
      const inventoryData = await fetch(
        `${API_BASE_URL}/inventory/${id}/public`
      );
      const jsonData = await inventoryData.json();
      console.log("xyz value is", jsonData);
      if (jsonData) {
        const data = jsonData.data;
        setInventoryData(jsonData.data);
        console.log("final data is", data);
      }
    } catch (err) {
      console.log(err, "err");
    }
    setIsLoading(false);
    setFlag(1);
  };

  console.log("inventory data", inventoryData);

  useEffect(() => {
    getData();
  }, [apiCall]);

  //edit section

  const handleMouseEnter = (index, type) => {
    console.log("index value", index);
    setEditText("");
    hoverTimeout.current = setTimeout(() => {
      setHovered(index);
      setShowEditModal(true);
      setPopconfirmVisible(true);
      setEditType(type);
    }, 1000); // 2 seconds delay
  };

  const handleMouseLeave = () => {
    clearTimeout(hoverTimeout.current);
    // setHovered(null);
    setShowEditModal(false);
  };

  const handleTextSubmit = async (body, fun) => {
    const loadingMessage = message.loading(`updating...`, 0);
    console.log("body is", body);
    if (fun == "inventoryEdit") {
      const updated = await InventoryService.put(id, body);
      if (updated) {
        setPopconfirmVisible(false);
        message.success(`updated value`);

        loadingMessage();
      }
    }
    if (fun == "inspectionEdit") {
      const updated = await InventoryService.putAdminInspection(id, body);
      if (updated) {
        setPopconfirmVisible(false);
        message.success(`updated value`);

        loadingMessage();
      }
    }
    setShowEditModal(false);
    setApiCall(!apiCall);
  };

  const changeInventoryData = (value, fun) => {};

//   const htmlString = document.getElementById('inspection-component').innerHTML
// console.log('html string is',document.getElementById('inspection-component'),htmlString);

  return (
    <ModalContext.Provider
      value={{
        hovered,
        setHovered,
        editType,
        setEditType,
        handleMouseEnter,
        handleMouseLeave,
        showEditModal,
        setShowEditModal,
        editText,
        setEditText,
        EditOutlined,
        handleTextSubmit,
        popconfirmVisible,
        setPopconfirmVisible,
        inventoryData,
        setInventoryData,
        id,
        getData,
        editAccess
      }}
    >
      {isLoading ? (
        <Spin
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
          size="medium"
        />
      ) : (
        <>
          {/* <PopConfirmBox inventoryData = {inventoryData} /> */}
          <div id="inspection-component" style={{ maxWidth: "100vw", overflow: "hidden" }}>
            <Navbar />
            <div className="page-outer-div">
              {inventoryData && (
                <VehicleInspection
                  vehicleType={inventoryData?.vehicleType}
                  reportId={inventoryData?.certificationId}
                  loanNumber={inventoryData?.loanNumber}
                  location={inventoryData?.inspectionVenue?.location}
                  date={inventoryData?.details?.registrationDate}
                  make={inventoryData?.make?.name}
                  model={inventoryData?.model?.name}
                  version={inventoryData?.version?.name}
                  fuelType={inventoryData?.fuelType?.name}
                />
              )}
              <CarInspection
                location={inventoryData?.inspectionVenue?.location}
                details={inventoryData?.details}
                customer={inventoryData?.customer}
                valuationPrice={
                  inventoryData?.finalPrice
                    ? inventoryData?.finalPrice
                    : inventoryData?.autoPrice
                    ? inventoryData?.autoPrice
                    : null
                }
                vehicleType={inventoryData?.vehicleType?.name}
                images={inventoryData?.vehicleImages}
                updatedAt={inventoryData?.updatedAt}
                typeValue={inventoryData?.type}
                scores={inventoryData?.Scores}
                vehicleCondition={inventoryData?.details?.condition}
              />
              <CarReport
                pdfLink={inventoryData?.pdfLink}
                scores={inventoryData?.Scores}
                imageLink={inventoryData?.imagesFolderLink}
                // videoLinks={[
                //   "https://evaluation-test.s3.amazonaws.com/inventory/original/0fb524c3-eeb6-44fa-8199-f3bb5d59477b.mp4",
                //   "https://evaluation-test.s3.amazonaws.com/inventory/original/0fb524c3-eeb6-44fa-8199-f3bb5d59477b.mp4",
                // ]}
                videoLinks={[
                  {name:'exterior', link:inventoryData?.inspectionVideo?.exterior},
                  {name:'interior', link:inventoryData?.inspectionVideo?.interior},
                ]}
                interiorLink = {inventoryData?.inspectionVideo?.interior}
                exteriorLink = {inventoryData?.inspectionVideo?.exterior}
                inspectionVideo = {inventoryData?.inspectionVideo}
                details={{
                  fuelType: inventoryData?.fuelType?.name,
                  insurance:
                    inventoryData?.otherDetails?.insurancetype == "Available"
                      ? true
                      : false,
                  color: inventoryData?.color?.name,
                  transmission: inventoryData?.transmission?.type,
                  odometerReading: inventoryData?.odometerReading,
                  rc: inventoryData?.otherDetails?.rcAvailable,
                  noOfOwners: inventoryData?.details?.numberOfOwners,
                  hypothecation: inventoryData?.otherDetails?.hypothecation,
                  mfgYear: inventoryData?.details?.mfgYear,
                  regYear: inventoryData?.details?.registrationDate,
                }}
                categoriesArray={categoriesArray}
                clientName={inventoryData?.mainClient?.name}
                inventoryData={inventoryData}
              />
              <VehicleSummary
                remark={inventoryData?.remark}
                inventoryData={inventoryData}
              />
              <VahanDetails
                vahanDetails={inventoryData?.vahanDetails}
                inventoryData={inventoryData}
              />
              {categoriesArray.map((data) => (
                <InteriorComponent
                  title={data}
                  questionsList={inventoryData?.inspectionDetails?.map(
                    (item) => ({
                      questionId: item.question?._id,
                      answer: item.answer,
                    })
                  )}
                  dataArray={inventoryData?.inspectionDetails?.filter(
                    (item) => item?.question?.category?.name == data
                  )}
                />
              ))}
              {/* <InteriorComponent
              title="INTERIORS"
              dataArray={inventoryData?.inspectionDetails?.filter(
                (item) => item.question.category.name == "INTERIOR DETAILS"
              )}
            />
            <InteriorComponent
              title="EXTERIORS"
              data={exteriorData}
              dataArray={inventoryData?.inspectionDetails?.filter(
                (item) => item.question.category.name == "EXTERIOR DETAILS"
              )}
            />
            <InteriorComponent
              title="ELECTRICALS"
              dataArray={inventoryData?.inspectionDetails?.filter(
                (item) => item.question.category.name == "ELECTRICALS"
              )}
            />
            <InteriorComponent
              title="ENGINE AND TRANSMISSION"
              dataArray={inventoryData?.inspectionDetails?.filter(
                (item) =>
                  item.question.category.name == "ENGINE AND TRANSMISSION"
              )}
            />
            <InteriorComponent
              title="BODY AND FRAME"
              dataArray={inventoryData?.inspectionDetails?.filter(
                (item) => item.question.category.name == "BODY AND FRAME"
              )}
            />
            <InteriorComponent
              title="OTHER DETAILS"
              dataArray={inventoryData?.inspectionDetails?.filter(
                (item) => item.question.category.name == "OTHER DETAILS"
              )}
            /> */}
              <QAComponent />
              <DisclaimerSection />
            </div>
          </div>
        </>
      )}
    </ModalContext.Provider>
  );
}

export default InspectionReport;
