import React from "react";
import InteriorItem from "./InteriorItem";
import styles from "./InteriorGrid.module.css";

const InteriorGrid = ({ items, dataArray, questionsList }) => {
  const image =
    "/img/report/round-tick.svg";

  return (
    <div className={styles.grid}>
      <div className={styles.column}>
        {dataArray &&
          dataArray.map((item, index) => (
            <InteriorItem
              key={index}
              title={item?.question?.name}
              imageSrc={image}
              rating={item.answer}
              id={item?.question?._id}
              dataArray = {dataArray}
              questionsList = {questionsList}
            />
          ))}
      </div>
      {/* <div className={styles.column}>
        {interiorItems.slice(6).map((item, index) => (
          <InteriorItem key={index + 6} title={item.title} imageSrc={item.imageSrc} />
        ))}
      </div> */}
    </div>
  );
};

export default InteriorGrid;
