import { Button, Input, Modal, Popconfirm } from 'antd'
import React, { useContext, useState } from 'react'
import { ModalContext } from '.';

function PopConfirmBox({inventoryData}) {

    const {
        hovered,
        setHovered,
        editType,
        setEditType,
        handleMouseEnter,
        handleMouseLeave,
        showEditModal,
        setShowEditModal,
        editText,
        setEditText,
        EditOutlined,
        handleTextSubmit,
        popconfirmVisible,
        setPopconfirmVisible,
        setInventoryData
      } = useContext(ModalContext);

      const [inputValue, setInputValue] = useState('');

      const handleVisibleChange = (newVisible) => {
        setPopconfirmVisible(newVisible);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleSubmit = () => {
        console.log('Input Value:', inputValue);
        setPopconfirmVisible(false);
    };

    const handleCancel = () => {
        setPopconfirmVisible(false);
    };

      console.log('popconfirm', popconfirmVisible)

      const content = (
        <div>
            <p>Enter {hovered}:</p>
            <Input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                style={{ marginBottom: '10px' }}
            />
            <div>
                <Button type="primary" onClick={()=>handleTextSubmit(hovered, 'inventoryEdit',inputValue)} style={{ marginRight: '10px' }}>
                    Submit
                </Button>
                <Button onClick={handleCancel}>Cancel</Button>
            </div>
        </div>
    );

    const handleOk = () => {
        console.log('Input Value:', inputValue);
        setPopconfirmVisible(false);
    };

  return (
//     <Modal
//     title="Enter your input"
//     visible={popconfirmVisible}
//     onOk={handleOk}
//     onCancel={handleCancel}
    
// >
//     <p>Enter your input:</p>
//     <Input
//         type="text"
//         value={inputValue}
//         onChange={handleInputChange}
//         style={{ marginBottom: '10px' }}
//     />
// </Modal>
<Popconfirm
title={content}
visible={popconfirmVisible}
onVisibleChange={handleVisibleChange}
okButtonProps={{ style: { display: 'none' } }}
cancelButtonProps={{ style: { display: 'none' } }}
>
<Button className="absolute-button" type="primary">Open Popconfirm</Button>
</Popconfirm>
  )
}

export default PopConfirmBox
