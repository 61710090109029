import React from "react";
import styles from "./VehicleInspection.module.css";
import Header from "./Header";
import VehicleDetails from "./VehicleDetails";
import { Link, Element, animateScroll as scroll } from "react-scroll";

const VehicleInspection = ({
  vehicleType,
  reportId,
  loanNumber,
  location,
  date,
  make,
  model,
  version,
  fuelType,
}) => {
  return (
    <div className={styles.outerContainer}>
      <Element name="home-page">
        <div className={styles.container}>
          <Header
            vehicleType={vehicleType}
            reportId={reportId}
            loanNumber={loanNumber}
          />
          <VehicleDetails
            date={date}
            make={make}
            model={model}
            version={version}
            fuelType={fuelType}
            location = {location}
          />
        </div>
      </Element>
    </div>
  );
};

export default VehicleInspection;
