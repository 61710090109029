import React, { useContext, useEffect, useState } from "react";
import styles from "./styles/CarReport.module.css";
import { DatePicker, Input, Select } from "antd";
import VehicleTypeService from "services/VehicleType";
import { ModalContext } from "..";
import FuelTypeService from "services/FuelTypeService";
import ColorService from "services/ColorService";
import TransmissionService from "services/TransmissionService";
import moment from "moment";

const VehicleDetails = ({ details, clientName, inventoryData }) => {
  const dataCheck = (value) => {
    if (value) {
      return value;
    } else {
      return "N/A";
    }
  };

  const {
    hovered,
    setHovered,
    editType,
    setEditType,
    handleMouseEnter,
    handleMouseLeave,
    showEditModal,
    setShowEditModal,
    editText,
    setEditText,
    EditOutlined,
    handleTextSubmit,
    popconfirmVisible,
    setPopconfirmVisible,
    editAccess
  } = useContext(ModalContext);

  const detailsObject = inventoryData?.details;
  const fuelType = inventoryData?.fuelType;
  const otherDetails = inventoryData?.otherDetails;
  const color = inventoryData?.color;
  const transmission = inventoryData?.transmission;

  const [detailsEdit, setDetailsEdit] = useState(false);
  const [formData, setFormData] = useState({
    fuelType: fuelType?.name,
  });
  const [vehicleTypeData, setVehicleTypeData] = useState([]);
  const { Option } = Select;
  const [selectedIndex, setSelectedIndex] = useState("");
  const [defaultSelect, setDefaultSelect] = useState("");
  const [defaultInput, setDefaultInput] = useState("");
  const [colorData, setColorData] = useState([]);
  const [transmissionData, setTransmissionData] = useState([]);
  const [inputValue, setinputValue] = useState("");
  const [date, setDate] = useState();

  const fetchFueltype = async () => {
    const response = await FuelTypeService.getAll();
    setVehicleTypeData(response.data);
  };

  const fetchTransmissionData = async () => {
    const response = await TransmissionService.getAll();
    setTransmissionData(response.data);
  };

  const fetchColors = async () => {
    const response = await ColorService.getAll();
    setColorData(response.data);
  };

  useEffect(() => {
    fetchColors();
    fetchFueltype();
    fetchTransmissionData();
  }, []);

  const detailItems = [
    {
      icon: "/img/report/fuel-type.svg",
      label: "FUEL TYPE :",
      type: "select",
      value: dataCheck(details.fuelType),
      data: vehicleTypeData,
      index: "fuelType",
      editMode: "inspectionEdit",
    },
    {
      icon: "/img/report/insurance-icon.svg",
      label: "Insurance :",
      type: "select",
      value: inventoryData?.otherDetails?.insuranceType,
      data: [
        { _id: "Available", name: "Available" },
        { _id: "Expired", name: "Expired" },
      ],
      index: "insuranceType",
      editMode: "inspectionEdit",
    },
    {
      icon: "/img/report/color-icon.svg",
      label: "COLOR :",
      type: "select",
      value: dataCheck(details?.color),
      data: colorData,
      index: "color",
      editMode: "inspectionEdit",
    },
    {
      icon: "/img/report/transmission-icon.svg",
      label: "TRANSMISSION :",
      type: "select",
      value: inventoryData?.transmission?.name,
      data: transmissionData,
      index: "transmission",
      editMode: "inspectionEdit",
    },
    {
      icon: "/img/report/odometer-icon.svg",
      label: "ODOMETER :",
      type: "input",
      value: dataCheck(details?.odometerReading),
      index: "odometer",
      editMode: "inspectionEdit",
    },
    {
      icon: "/img/report/rc-icon.svg",
      label: "RC Available :",
      type: "select",
      value: inventoryData?.otherDetails?.rcAvailable,
      data: [
        { _id: "yes", name: "yes" },
        { _id: "no", name: "no" },
      ],
      index: "rc",
      editMode: "inspectionEdit",
    },
    {
      icon: "/img/report/owner-icon.svg",
      label: "NO of Owner's :",
      type: "input",
      value: dataCheck(inventoryData?.otherDetails?.numberOfOwners),
      index: "owner",
      editMode: "inspectionEdit",
    },
    {
      icon: "/img/report/hypothecated-icon.svg",
      label: "Car hypothecated :",
      type: "select",
      value: inventoryData?.otherDetails?.hypothecation ? "Yes" : "No",
      data: [
        { _id: true, name: "true" },
        { _id: false, name: "false" },
      ],
      index: "hypothecation",
      editMode: "inspectionEdit",
    },
    {
      icon: "/img/report/mfg-icon.svg",
      label: "Manufacturing year :",
      type: "input",
      value: dataCheck(details?.mfgYear),
      index: "mfgYear",
      editMode: "inspectionEdit",
    },
    {
      icon: "/img/report/reg-icon.svg",
      label: "REGISTRATION YEAR :",
      type: "datepicker",
      value: dataCheck(details?.regYear?.slice(0, 4)),
      index: "regYear",
      editMode: "inspectionEdit",
    },
  ];

  // useEffect(()=>{
  //   if(selectedIndex.index == "fuelType"){
  //     setDefaultSelect(inventoryData?.fuelType?._id)
  //   }
  //   if(selectedIndex.index == "insuranceType"){
  //     setDefaultSelect(inventoryData?.otherDetails?.insuranceType)
  //   }

  // },[selectedIndex])

  const handleChange = (date, dateString) => {
    let body = {};
    let isoString;
    // setDate(date);
    console.log("date value is", date, dateString); // date is a moment object, dateString is a formatted string
    if (date) {
      isoString = date.toISOString();
      setDate(isoString);
      console.log(isoString, "iso date"); // Outputs the date in ISO 8601 format
    } else {
      setDate(null);
    }

    if (selectedIndex.index == "regYear") {
      body = {
        vehicleDetail: {
          ...inventoryData?.details,
          registrationDate: isoString,
        },
      };
    }

    handleTextSubmit(body, selectedIndex.editMode);
  };

  const handlePressEnter = () => {
    let body = {};

    if (selectedIndex.index == "odometer") {
      body = { odometerReading: inputValue };
    }
    if (selectedIndex.index == "owner") {
      body = {
        otherVehicleDetail: {
          ...inventoryData?.otherDetails,
          numberOfOwners: inputValue,
        },
      };
    }
    if (selectedIndex.index == "mfgYear") {
      body = {
        vehicleDetail: {
          ...inventoryData?.details,
          mfgYear: Number(inputValue),
        },
      };
    }
    console.log("body is", body);
    handleTextSubmit(body, selectedIndex.editMode);
    // Handle the event here (e.g., submit the form, make an API call, etc.)
  };

  const selectChange = (value) => {
    let body = {};
    if (selectedIndex.index == "fuelType") {
      body = { fuelTypeId: value };
    }
    if (selectedIndex.index == "insuranceType") {
      body = {
        otherVehicleDetail: {
          ...inventoryData?.otherDetails,
          insuranceType: value,
        },
      };
    }
    if (selectedIndex.index == "color") {
      body = { colorId: value };
    }
    if (selectedIndex.index == "transmission") {
      body = { transmissionId: value };
    }
    if (selectedIndex.index == "rc") {
      body = {
        otherVehicleDetail: {
          ...inventoryData?.otherDetails,
          rcAvailable: value,
        },
      };
    }
    if (selectedIndex.index == "hypothecation") {
      body = {
        otherVehicleDetail: {
          ...inventoryData?.otherDetails,
          hypothecation: value,
        },
      };
    }

    console.log("body is", body);
    handleTextSubmit(body, selectedIndex.editMode);
  };

  console.log("default select is", defaultSelect);

  const selectDefaultValue = (item) => {
    if (item.index == "fuelType") {
      return inventoryData?.fuelType?._id;
    }
    if (item.index == "insuranceType") {
      return inventoryData?.otherDetails?.insuranceType;
    }
    if (item.index == "color") {
      return inventoryData?.color._id;
    }
    if (item.index == "transmission") {
      return inventoryData?.transmission._id;
    }
    if (item.index == "odometer") {
      return inventoryData?.odometerReading;
    }
    if (item.index == "rc") {
      return inventoryData?.otherDetails?.rcAvailable;
    }
    if (item.index == "owner") {
      return inventoryData?.otherDetails?.numberOfOwners;
    }
    if (item.index == "hypothecation") {
      return inventoryData?.otherDetails?.hypothecation;
    }
    if (item.index == "mfgYear") {
      return inventoryData?.details?.mfgYear;
    }
    if (item.index == "regYear") {
      return moment(inventoryData?.details?.registrationDate);
    }
  };
  return (
    <section className={styles.rightColumn}>
      <div className={styles.detailsContainer}>
        <div className={styles.requesterInfo}>
          <div className={styles.requesterHeader}>
            <img
              loading="lazy"
              src="/img/report/requested-by.svg"
              className={styles.requesterIcon}
              alt=""
            />
            <div className={styles.requesterTitle}>REPORT REQUESTED BY</div>
          </div>
          <div className={styles.requesterName}>{clientName}</div>
        </div>
        <div className={styles.vehicleDetails}>
          {detailItems.map((item, index) => (
            <div key={index} className={styles.detailItem}>
              <div className={styles.detailLabel}>
                <img
                  data-aos="fade-down"
                  loading="lazy"
                  src={item.icon}
                  className={styles.detailIcon}
                  alt=""
                />
                <div className={styles.detailText}>{item.label}</div>
              </div>
              <div
                className={styles.detailValue}
                onClick={() => {
                  setSelectedIndex(item);
                  setDetailsEdit(true);
                }}
              >
                {(detailsEdit && editAccess && selectedIndex.index == item.index ) ? (
                  item.type === "input" ? (
                    <Input
                      defaultValue={selectDefaultValue(item)}
                      onBlur={() => setDetailsEdit(false)}
                      className = {styles.detailsInput}
                      onPressEnter={() => handlePressEnter(item)}
                      onChange={(e) => setinputValue(e.target.value)}
                    />
                  ) : item.type === "select" ? (
                    <Select
                      onBlur={() => setDetailsEdit(false)}
                      onChange={selectChange}
                      className={styles.detailsSelect}
                      placeholder="Select an option"
                      defaultValue={selectDefaultValue(item)}
                      dropdownStyle={{ fontSize: '12px' }}
                    >
                      {item.data &&
                        item.data.map((card) => (
                          <Option key={card._id} value={card._id}>
                            {card.name}
                          </Option>
                        ))}
                    </Select>
                  ) : (
                    <DatePicker
                      className = {styles.detailsDate}
                      defaultValue={selectDefaultValue(item)}
                      onBlur={() => setDetailsEdit(false)}
                      onChange={handleChange}
                    />
                  )
                ) : (
                  <span className={styles.vahanSpan}>{item.value}</span>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.requestDiv}></div>
    </section>
  );
};

export default VehicleDetails;
