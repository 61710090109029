import fetch from 'auth/FetchInterceptor';

const UploadFileService = {};

const api = 'filemanager';

UploadFileService.post = function (data) {
  return fetch({
    url: `${api}/images/upload`,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

UploadFileService.postWithoutWaterMark = function (data) {
  return fetch({
    url: `${api}/images/upload_without_watermark`,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

UploadFileService.postVideo = function (data) {
  return fetch({
    url: `${api}/video/upload`,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

UploadFileService.postPdf = function (data) {
  return fetch({
    url: `${api}/pdf/upload`,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

UploadFileService.validURL = (str) => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
};

export default UploadFileService;
