import { Card, Form, Input, message, Modal, Upload } from "antd";
import Flex from "components/shared-components/Flex";
import UploadButton from "components/shared-components/UploadButton/UploadButton";
import React, { useContext, useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import UploadFileService from "services/UploadFileService";
import { ModalContext } from "..";
import InventoryService from "services/InventoryService";
import { EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import styles from './CarInspection.module.css'

function ImageModal({ overlayVisible, setOverlayVisible, item, vehicleArray }) {
  const {
    hovered,
    setHovered,
    editType,
    setEditType,
    handleMouseEnter,
    handleMouseLeave,
    showEditModal,
    setShowEditModal,
    editText,
    setEditText,
    EditOutlined,
    handleTextSubmit,
    popconfirmVisible,
    setPopconfirmVisible,
    id,
    getData,
  } = useContext(ModalContext);

  const [images, setImages] = useState([
    {
      uid: 0,
      name: "image.png",
      status: "done",
      url: item?.image,
    },
  ]);

  const [formvalues, setFormvalues] = useState({
    info: item.info,
    longitude: item.longitude,
    latitude: item.latitude,
  });

  const title = item && item.title;
  console.log("vehicle array is", vehicleArray);

  const showModal = () => {
    setOverlayVisible(true);
  };

  const handleOk = async () => {
    const loadingMessage = message.loading(`updating...`, 0);
    setOverlayVisible(false);

    const uploadToAWS = async (file) => {
      console.log("file to upload", file);

      let url = null;

      if (!UploadFileService.validURL(file?.url)) {
        const formData = new FormData();
        formData.append("files", file.originFileObj);
        url = await UploadFileService.post(formData);
        console.log("uploaded file and got url", url.data[0]);
        return url.data;
      }
    };

    let imageUrls = [];

    if (images.length > 0) {
      try {
        const uploadResponses = await Promise.all(images.map(uploadToAWS));
        imageUrls = uploadResponses.map((url) => url);
        // Call your POST API with the imageUrls
        console.log("Image URLs:", imageUrls, images);

        
    
        //   values.images = imageUrls;
        // message.success("Images uploaded successfully.");
      } catch (error) {
        console.error("Error uploading images:", error);
        message.error("Failed to upload images.");
      }
    }

    const body = {
        images: {},
      };


    const values = await form.validateFields();
    console.log("new values are", images);

    body.images[title] = {
      image: imageUrls[0] != undefined ? imageUrls[0][0] : (images?.length == 0 ? 'null' : item.image),
      info: formvalues.info,
      latitude: Number(formvalues.latitude),
      longitude: Number(formvalues.longitude),
    };

    vehicleArray.forEach((item) => {
      if (item.title != title) {
        body.images[item.title] = {
          image: item.image,
          info: item.info,
          latitude: item.latitude,
          longitude: item.longitude,
        };
      }
    });

    const response = await InventoryService.putAdminInspection(id, body);
    console.log("final response", response);
    if (response) {
      getData();
      loadingMessage()
      message.success('updated Successfully')
    }

    console.log("final body is", body);
  };

  const handleCancel = () => {
    setOverlayVisible(false);
  };

  //image handle

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  console.log("file list", images);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleRemove = (file) => {
    setImages(images.filter((img) => img.uid !== file.uid));
  };

  const itemRender = (originNode, file) => (
    <div>
      <img
        src={file.url || file.preview}
        alt="uploaded file"
        style={{ width: '100%' }}
      />
      <div className={styles.itemRender}>
        <EyeOutlined onClick={() => handlePreview(file)} />
        <DeleteOutlined onClick={() => handleRemove(file)} />
      </div>
    </div>
  );

  const handleChange = ({ fileList }) => {
    setImages(fileList.slice(-1)); //if only one image to be uploaded setImages(fileList.slice(-1);)
    //    form.validateFields(['image']);    //to validate image field when new image upload so that - when we initially submit without uploading image, error shows, then we upload image, error doesnt disappear-to disappear error this condition
  };

  const handleCancelImage = () => setPreviewVisible(false);

  //antd form

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      info: item.info,
      latitude: item.latitude,
      longitude: item.longitude,
    });
  }, [item]);

  const onFinish = async () => {
    const values = await form.validateFields();
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Modal
      title={item.title}
      visible={overlayVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Card title="Images">
        <Upload
          name="images"
          listType="picture-card"
          accept="image/png, image/jpeg, image/jpg, image/gif, image/bmp, image/webp, image/svg+xml"
          fileList={images}
          onPreview={handlePreview}
          onChange={handleChange}
          multiple
          beforeUpload={() => false} //not to trigger upload when selecting images
          // itemRender={itemRender}
          // style={{position: "relative"}}
        >
          {uploadButton}
        </Upload>
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="Info"
            name="info"
            // rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input
              style={{ width: "100%" }}
              onChange={(e) =>
                setFormvalues({ ...formvalues, info: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item
            label="Longitude"
            name="longitude"
            // rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input
              onChange={(e) =>
                setFormvalues({ ...formvalues, longitude: e.target.value })
              }
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            label="Latitude"
            name="latitude"
            // rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input
              onChange={(e) =>
                setFormvalues({ ...formvalues, latitude: e.target.value })
              }
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Form>
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={handleCancelImage}
        >
          <img alt="Preview" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </Card>
    </Modal>
  );
}

export default ImageModal;
