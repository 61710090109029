import fetch from 'auth/FetchInterceptor';

const TransmissionService = {};

const api = 'transmission';

TransmissionService.getAll = function (params) {
  return fetch({
    url: `${api}/fetch`,
    method: 'GET',
    params
  });
};

TransmissionService.get = function (id) {
  return fetch({
    url: `${api}/${id}`,
    method: 'GET'
  });
};

TransmissionService.post = function (data) {
  return fetch({
    url: `${api}`,
    method: 'POST',
    data: data
  });
};

TransmissionService.put = function (id, data) {
  return fetch({
    url: `${api}/${id}`,
    method: 'PUT',
    data: data
  });
};

TransmissionService.generateTransmissionReport = function (params) {
  return fetch({
    url: `${api}/generate`,
    method: 'GET',
    params
  });
};
export default TransmissionService;
