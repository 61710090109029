import React, { useContext, useEffect, useState } from "react";
import styles from "./VehicleInspection.module.css";
import WOW from "react-wow";
import "animate.css"; // Import animate.css for the animations
import { Input } from "antd";
import { ModalContext } from "..";
import Commoninput from "../Commoninput";
import InputCommon from "../Commoninput";
import InputSelect from "../CommonSelect";

const VehicleDetails = ({ date, make, model, version, fuelType, location }) => {

  const {
    hovered,
    setHovered,
    editType,
    setEditType,
    handleMouseEnter,
    handleMouseLeave,
    showEditModal,
    setShowEditModal,
    editText,
    setEditText,
    EditOutlined,
    handleTextSubmit,
    popconfirmVisible,
    setPopconfirmVisible,
    editAccess
  } = useContext(ModalContext);

  const [locationValue, setLocationValue] = useState(location);
  const [locationEdit, setlocationEdit] = useState(false);
  const [makeEdit,setmakeEdit] = useState(false)
  const [modelEdit,setmodelEdit] = useState(false)
  const [versionEdit,setversionEdit] = useState(false)
  const [makeValue, setmakeValue] = useState("")
  const [modelValue, setmodelValue] = useState("")
  const [versionValue, setversionValue] = useState("")

  return (
    <div className={styles.vehicleDetails}>
      <span data-aos="fade-down" className={`${styles.model}`}>
        {date && 'MMV-'} {date?.slice(0, 4)} 
        {/* {makeEdit ? (
           <InputSelect
           body = {{inspectionVenue: {location: locationValue}}}
           editSection = {setmakeEdit}
           valueStore = {setmakeValue}
           value = {makeValue}
           editMode = "inspectionEdit"
           data = {[]}
           />
        ) : (
          {make}
        )} */}
        {make}
         {model} {date && '|'} {version} {fuelType}
      </span>
      <div className={styles.location}>
        <img
          loading="lazy"
          src="/img/report/location-icon.svg"
          className={styles.locationIcon}
        />
        <div className={styles.locationName}>
          {locationEdit && editAccess ? (
        // <Input
        //     onPressEnter={() =>
        //       handleTextSubmit({inspectionVenue: {location: locationValue}}, "inventoryEdit")
        //     }
        //     onBlur={() => setlocationEdit(false)}
        //     onChange={(e) => setLocationValue(e.target.value)}
        //     type="text"
        //     value={locationValue}
        //   />
          <InputCommon
          body = {{inspectionVenue: {location: locationValue}}}
          editSection = {setlocationEdit}
          valueStore = {setLocationValue}
          value = {locationValue}
          editMode = "inventoryEdit"
          />
        ) : (<span className={styles.locSpan} onClick={()=>setlocationEdit(!locationEdit)}>{location}</span>)}
        </div>
      </div>
    </div>
  );
};

export default VehicleDetails;
