import React, { useEffect, useState } from "react";
import styles from "./styles/CarReport.module.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { getStatusClassNames } from "antd/es/_util/statusUtils";
import { useSpring, animated } from "react-spring";

const ReportItem = ({
  icon,
  title,
  score,
  status,
  image,
  type,
  img,
  rating,
  review,
  name,
}) => {
  // const percentage = (rating / 10) * 100;
  const [percentage, setPercentage] = useState(0);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setPercentage((rating / 10) * 100);
  //   }, 1000); // Delay the animation start
  // }, [review]);

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500) {
        setAnimate(true);
      } else {
        setAnimate(false);
        setPercentage(0); // Reset the percentage if scrolling back up
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (animate) {
      const timer = setTimeout(() => {
        setPercentage((score / 10) * 100);
      }, 500); // Delay the animation start

      return () => clearTimeout(timer);
    }
  }, [animate, score]);

  const colorValue = (value) => {
    if (value >= 9) {
      return "#00C90A";
    }
    if (value == 7 || value == 8) {
      return "#FFE500";
    }
    if (value >= 4 && value <= 6) {
      return "#FFA500";
    }
    if (value >= 1 && value <= 3) {
      return "#FF1F00";
    }
  };

  const ratingValue = (value) => {
    if (value >= 9) {
      return "Excellent";
    }
    if (value == 7 || value == 8) {
      return "Good";
    }
    if (value >= 4 && value <= 6) {
      return "Average";
    }
    if (value >= 1 && value <= 3) {
      return "Bad";
    }
  };

  return (
    <div className={styles.reportItem}>
      <div className={styles.reportContent}>
        <div className={styles.reportDetails}>
          <div className={styles.reportHeader}>
            <img
              data-aos="fade-down"
              data-aos-duration="2000"
              loading="lazy"
              src="/report-img/common-icon.svg"
              className={styles.reportIcon}
              alt=""
            />
            <p className={styles.reportTitle}>
              {name?.toLowerCase()?.replace(/\b\w/g, (char) => char.toUpperCase())}
            </p>
          </div>
          <div className={styles.reportScore}>
            <div className={styles.scoreValue}>
              {score < 10 ? `0${Math.round(score)}` : Math.round(score)}/10
            </div>
            <div className={styles.scoreText}>
              {ratingValue(Math.round(score))}
            </div>
          </div>
        </div>
        <div className={styles.ratingCircle}>
          <CircularProgressbar
            value={percentage}
            styles={buildStyles({
              textColor: colorValue(Math.round(score)),
              pathColor: colorValue(Math.round(score)),
              trailColor: "#FFF",
              pathTransition: "stroke-dashoffset 1.5s ease 0s",
            })}
            strokeWidth={18}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportItem;
