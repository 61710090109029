import fetch from 'auth/FetchInterceptor';

const VehicleTypeService = {};

const api = 'vehicle_type';

VehicleTypeService.getAll = function (params) {
  return fetch({
    url: `${api}/fetch`,
    method: 'GET',
    params
  });
};

VehicleTypeService.get = function (id) {
  return fetch({
    url: `${api}/${id}`,
    method: 'GET'
  });
};

VehicleTypeService.post = function (data) {
  return fetch({
    url: `${api}`,
    method: 'POST',
    data: data
  });
};

VehicleTypeService.put = function (id, data) {
  return fetch({
    url: `${api}/${id}`,
    method: 'PUT',
    data: data
  });
};

VehicleTypeService.delete = function (id) {
  return fetch({
    url: `${api}/${id}`,
    method: 'DELETE'
  });
};

VehicleTypeService.generateVehicleTypeReport = function (params) {
  return fetch({
    url: `${api}/generate`,
    method: 'GET',
    params
  });
};
export default VehicleTypeService;
