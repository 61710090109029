import React, { useContext, useEffect, useState } from 'react';
import styles from './CarInspection.module.css';
import WOW from 'react-wow';
import 'animate.css'; // Import animate.css for the animations
import InputCommon from '../Commoninput';
import InputSelect from '../CommonSelect';
import { ModalContext } from '..';

const StatusSection = ({typeValue, vehicleCondition, details}) => {

  const {
    editAccess
   } = useContext(ModalContext);


  const [styleValue, setStyleValue] = useState({})
  const [iconImage, setIconimage] = useState("")
  const [vehicleEdit, setvehicleEdit] = useState(false);
  const [vehicleValue, setVehicleValue] = useState(vehicleCondition)
  const [colorChange, setColorChange] = useState(false)

  useEffect(()=>{
  if(vehicleCondition == "Non Accident"){
    setStyleValue({background: "#00C90A"})
    setIconimage("/img/report/tick-mark.svg")
  }
  if(vehicleCondition == "Bad"){
    setStyleValue({background: "#FF0000"})
    setIconimage("/report-img/bad-icon.svg")
  }
  if(vehicleCondition == "Poor Mechanical Condition"){
    console.log('entered here')
    setStyleValue({background: "#FFC107"})
    setIconimage("/report-img/poor-icon.svg")
  }
  if(vehicleCondition == "Need Refurb"){
    setStyleValue({background: "#0000FF"})
    setIconimage("/report-img/refurb-icon.svg")
  }
  if(!vehicleCondition){
    setStyleValue({background: "#00C90A"})
    setIconimage("/img/report/tick-mark.svg")
  }
  },[details])

  return (
    <div className={styles.statusSection} >
      <div data-aos="zoom-in" className={`${styles.statusItem} wow fadeIn`}>DE-DUPE</div>
      {vehicleCondition && (
      <div data-aos="zoom-in" className={styles.damageStatus} style={styleValue}>
        <img src={iconImage} alt="No Damage" className={styles.damageIcon} />
        <span className={styles.damageText} onClick={()=>setvehicleEdit(true)}>
          {vehicleEdit && editAccess ? (
            <InputSelect
            body = {{vehicleDetail: {...details,condition: vehicleValue}}}
            editSection = {setvehicleEdit}
            valueStore = {setVehicleValue}
            value = {vehicleValue}
            editMode = "inspectionEdit"
            data = {[{_id: "Bad", name: "Bad"},
              {_id: "Non Accident", name: "Non Accident"},
              {_id: "Poor Mechanical Condition", name: "Poor Mechanical Condition"},
              {_id: "Need Refurb", name: "Need Refurb"},
            ]}
            setColorChange = {setColorChange}
            colorChange = {colorChange}
           
            />
          ) : (vehicleCondition ? vehicleCondition : "N/A")}
          </span>
      </div>
      )}
      <div data-aos="zoom-in" className={styles.statusItem}>{typeValue}</div>
    </div>
  );
};

export default StatusSection;