import React from "react";
import styles from "./VehicleSummary.module.css";

const ApprovalSection = () => {

  const signImage = "https://car-evaluation.s3.amazonaws.com/general/thumb/c881bd25-610a-4df2-8a8e-aa33af8a8954.JPG"

  return (
    <div className={styles.div8}>
      <div className={styles.div9}>
        <div className={styles.div10}>
          <img
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            loading="lazy"
            src="/img/report/verify-icon.svg"
            className={styles.img2}
          />
          <div className={styles.div11}>
            <div className={styles.div12}>Approved by:</div>
            <div className={styles.div13}>
            MADHUSUDHANA REDDY, <br /> 
              {/* License no : irda/ind/sla  */}
              {/* <br />{" "} */}
              www.vehiclemall.in
            </div>
          </div>
        </div>
      </div>
      <div
        data-aos="fade-down"
        data-aos-offset="300"
        data-aos-easing="ease-in-sine"
        className={styles.div14}
      >
        <img
          loading="lazy"
          // src="https://cdn.builder.io/api/v1/image/assets/TEMP/379dc186c07efd35e2b9361efddb211b0c38db7bdef6a462dd6c5c0b1c7bbce5?apiKey=4046fc8bd3874965a4b9d4e7624dfda6&"
          src={signImage}
          className={styles.img3}
        />
      </div>
    </div>
  );
};

export default ApprovalSection;
