import React, { useEffect, useState } from "react";
import NavItem from "./NavItem/NavItem";
import styles from "./Navbar.module.css";
import useWindowWidth from "components/useWindowWidth";
import { Link, Element, animateScroll as scroll } from "react-scroll";

const navItems = [
  { label: "Home", isActive: true, to: "", scrollTo: "home-page" },
  {
    label: "VAHAN DETAILS",
    isActive: false,
    to: "vahan-details",
    scrollTo: "vahan-details",
  },
  { label: "Q/A", isActive: false, to: "q/a", scrollTo: "q/a" },
  {
    label: "DISCLAIMER",
    isActive: false,
    to: "disclaimer",
    scrollTo: "disclaimer-page",
  },
];

const Navbar = () => {
  const [currentSection, setCurrentSection] = useState("Home");
  const [show, setShow] = useState(false);

  const width = useWindowWidth();
  // const [scrollPosition, setScrollPosition] = useState(0);

  // console.log('scroll position is', scrollPosition)

  // const handleScroll = () => {
  //   const position = window.pageYOffset;
  //   setScrollPosition(position);
  //   if(position == 2100){
  //     setCurrentSection("VAHAN DETAILS")
  //   }
  //   if(position == 200){
  //     setCurrentSection("Home")
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  return (
    <header className={styles.header}>
      <img
        loading="lazy"
        src="/img/report/main-logo.svg"
        alt="Company logo"
        className={styles.logo}
      />
      <nav className={styles.navigation}>
        {navItems.map((item, index) => (
          <NavItem
            key={index}
            label={item.label}
            to={item.to}
            isActive={item.isActive}
            scrollTo={item.scrollTo}
            currentSection={currentSection}
            setCurrentSection={setCurrentSection}
          />
        ))}
      </nav>
      {width < 1024 && (
        <>
          <img
            onClick={() => setShow(!show)}
            className={styles.burgerIcon}
            src="/report-img/burg-icon.svg"
          />
          <nav className={show ? styles.sidebar : styles.sidebarhide}>
            <img
              onClick={() => setShow(!show)}
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/0f74898aaaa826e7dbb5a6f71b8c7f5f051f91f1e00d273ae0e9d73a89046058?apiKey=4046fc8bd3874965a4b9d4e7624dfda6&"
              className={styles.closeIcon}
              alt=""
            />
            <ul className={styles.navList}>
              {navItems.map((item, index) => (
                <Link to={item.scrollTo} offset={-200}  >
                <li
                onClick={()=>setCurrentSection(item.label)}
                  className={`${styles.navItem} ${
                    currentSection == item.label ? styles.active : ""
                  }`}
                >
                  {item.label}
                </li>
                </Link>
              ))}
            </ul>
          </nav>
        </>
      )}
    </header>
  );
};

export default Navbar;
