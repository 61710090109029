import React from 'react'
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';
import 'animate.css'; // Import animate.css styles
import 'animate.css'; // Import animate.css styles
import WOW from 'wow.js'; // Import wow.js

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// Initialize wow.js after the initial render
const wow = new WOW({
    boxClass: 'wow', // Default class for wow.js elements
    animateClass: 'animated', // Default animate.css class
    offset: 0, // Viewport offset (default is 0)
    mobile: true, // Enable animations on mobile devices
    live: true // Apply animations on dynamically added elements
});
wow.init();

serviceWorker.unregister();
