import React from "react";
import ValuationSection from "./ValuationSection";
import InspectionLocation from "./InspectionLocation";
import VehicleDetails from "./VehicleDetails";
import StatusSection from "./StatusSection";
import ImageGallery from "./ImageGallery";
import styles from "./CarInspection.module.css";

const CarInspection = ({
  location,
  details,
  customer,
  valuationPrice,
  images,
  updatedAt,
  vehicleType,
  typeValue,
  scores,
  vehicleCondition
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <div className={styles.leftColumn}>
          <div className={styles.infoCard}>
            <ValuationSection
              valuationPrice={valuationPrice}
              vehicleType={vehicleType}
              scores={scores}
              vehicleCondition = {vehicleCondition}
            />
            <InspectionLocation location={location} />
            <VehicleDetails details={details} customer={customer} />
            <StatusSection typeValue={typeValue} vehicleCondition = {vehicleCondition} details={details} />
          </div>
        </div>
        <div className={styles.rightColumn}>
          <ImageGallery imageArray={images} updatedAt={updatedAt} />
          <div className={styles.rightSecondColumn}></div>
        </div>
      </div>
    </div>
  );
};

export default CarInspection;
