import React from 'react';
import DisclaimerContent from './DisclaimerContent';
import styles from './DisclaimerSection.module.css';
import { Link, Element, animateScroll as scroll } from 'react-scroll';

const DisclaimerSection = () => {
  return (
    <Element name="disclaimer-page">
    <section className={styles.disclaimerSection}>
      <h2 data-aos="fade-right" className={styles.disclaimerTitle}>DISCLAIMER</h2>
      <DisclaimerContent />
    </section>
    </Element>
  );
};

export default DisclaimerSection;