import React from "react";
import styles from "./VahanDetails.module.css";
import DetailRow from "./DetailRow";
import { Link, Element, animateScroll as scroll } from "react-scroll";
import VehicleDetails from "../car-inspection/VehicleDetails";

const VahanDetails = ({vahanDetails, inventoryData}) => {

  const dataCheck = (value) => {
    if(value){
      return value
    }else{
      return "N/A"
    }
  }

  const details = [
    { label: "OWNER NAME", value: dataCheck(vahanDetails?.owner) },
    {
      label: "PRESENT ADDRESS",
      value:
        dataCheck(vahanDetails?.presentAddress)
    },
    {
      label: "PERMANENT ADDRESS",
      value:
      dataCheck(vahanDetails?.permanentAddress)
    },
    { label: "FINANCIER", value: dataCheck(vahanDetails?.rcFinancer) },
    { label: "INSURER", value: dataCheck(vahanDetails?.vehicleInsuranceCompanyName) },
    { label: "REGISTRATION NUMBER", value: dataCheck(vahanDetails?.regNo) },
    { label: "REGISTRATION DATE", value: dataCheck(vahanDetails?.regDate) },
    { label: "REGISTERED AT RTO", value: dataCheck(vahanDetails?.rtoCode) },
    { label: "RC STATUS", value: (dataCheck(vahanDetails?.status))},
    // { label: "CHALLAN", value: "8000" },
    { label: "MANUFACTURING DATE", value: dataCheck(vahanDetails?.vehicleManufacturingMonthYear) },
    // { label: "OWNER SERIAL NUMBER", value: dataCheck(vahanDetails?.rc_owner_sr) },
    { label: "VEHICLE MODEL", value: dataCheck(vahanDetails?.model) },
    { label: "COLOR", value: dataCheck(vahanDetails?.vehicleColour) },
    { label: "FUEL", value: dataCheck(vahanDetails?.rc_fuel_desc) },
    { label: "CHASSIS NUMBER", value: dataCheck(vahanDetails?.chassis) },
    { label: "FUEL NORMS", value: dataCheck(vahanDetails?.normsType) },
    { label: "VEHICLE CATEGORY", value: dataCheck(vahanDetails?.vehicleCategory) },
    // { label: "NCRB STATUS", value: dataCheck(vahanDetails?.rc_ncrb_status) },
    { label: "VEHICLE CLASS DESCRIPTION", value: dataCheck(vahanDetails?.class) },
    { label: "BLACKLIST STATUS", value: dataCheck(vahanDetails?.blacklistStatus) },
    { label: "BODY TYPE", value: dataCheck(vahanDetails?.bodyType) },
    { label: "NOC DETAILS", value: dataCheck(vahanDetails?.nocDetails) },
    { label: "MOBILE NO", value: dataCheck(vahanDetails?.mobileNumber) },
    { label: "PERMIT NO", value: dataCheck(vahanDetails?.permitNumber) },
    // { label: "RC FITNESS VALIDITY", value: dataCheck(vahanDetails?.rc_fit_upto) },
    { label: "PERMIT ISSUE DATE", value: dataCheck(vahanDetails?.permitIssueDate) },
    { label: "RC TAX VALIDITY", value: dataCheck(vahanDetails?.vehicleTaxUpto) },
    { label: "PERMIT VALID FROM DATE", value: dataCheck(vahanDetails?.permitValidFrom) },
    { label: "INSURANCE POLICY NUMBER", value: dataCheck(vahanDetails?.vehicleInsurancePolicyNumber) },
    { label: "PERMIT VALID UPTO DATE", value: dataCheck(vahanDetails?.permitValidUpto) },
    { label: "INSURANCE VALIDITY", value: dataCheck(vahanDetails?.vehicleInsuranceUpto) },
    { label: "PERMIT TYPE", value: dataCheck(vahanDetails?.permitType) },
    { label: "RC STATUS AS ON", value: dataCheck(vahanDetails?.statusAsOn) },
  ];

  return (
    <Element name="vahan-details">
      <div className={styles.container}>
        <h1 className={styles.title} data-aos="fade-right">VAHAN DETAILS</h1>

        <div className={styles.detailsContainer}>
          {details.map((detail, index) => (
            <DetailRow key={index} label={detail.label} value={detail.value}  inventoryData={inventoryData} />
          ))}
        </div>
      </div>
    </Element>
  );
};

export default VahanDetails;
