import React from 'react';
import {
  AUTH_PREFIX_PATH,
  APP_PREFIX_PATH,
  REDIRECT_COMPONENT_KEY
} from 'configs/AppConfig';
import * as routeKeys from 'constants/RoutesKeys';

export const publicRoutes = [
  {
    key: 'login',
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() => import('views/auth-views/authentication/login'))
  },
  {
    key: 'forgot-password',
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import('views/auth-views/authentication/forgot-password')
    )
  },
  {
    key: 'VehicleInspectionReportKey',
    path: `/reports/inspection/:certificationId`,
    blankLayout: true,
    component: React.lazy(() =>
      import('views/app-views/pages/vehicle-inspection-report')
    )
  }
  // {
  //   key: 'register',
  //   path: `${AUTH_PREFIX_PATH}/register`,
  //   component: React.lazy(() =>
  //     import('views/auth-views/authentication/register')
  //   )
  // },
];

export const protectedRoutes = [
  {
    key: 'pages',
    isPage: true,
    path: `${APP_PREFIX_PATH}/pages`,
    component: React.lazy(() => import('views/app-views/pages'))
  },
  {
    key: 'pages.profile',
    isPage: true,
    path: `${APP_PREFIX_PATH}/pages/profile`,
    component: React.lazy(() => import('views/app-views/pages/profile'))
  },
  {
    key: REDIRECT_COMPONENT_KEY,
    path: `${APP_PREFIX_PATH}/dashboards`,
    component: React.lazy(() => import('views/app-views/dashboards/dashboard'))
  },
  {
    key: routeKeys.UserKey,
    path: `${APP_PREFIX_PATH}/dashboards/user-list/*`,
    component: React.lazy(() => import('views/app-views/dashboards/user'))
  },
  {
    key: routeKeys.EmployeeTypeKey,
    path: `${APP_PREFIX_PATH}/dashboards/employee-type-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/employee-type')
    )
  },
  {
    key: routeKeys.MakeKey,
    path: `${APP_PREFIX_PATH}/dashboards/make-list/*`,
    component: React.lazy(() => import('views/app-views/dashboards/make'))
  },
  {
    key: routeKeys.ModelKey,
    path: `${APP_PREFIX_PATH}/dashboards/model-list/*`,
    component: React.lazy(() => import('views/app-views/dashboards/model'))
  },
  {
    key: routeKeys.TransmissionKey,
    path: `${APP_PREFIX_PATH}/dashboards/transmission-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/transmission')
    )
  },
  {
    key: routeKeys.FuelTypeKey,
    path: `${APP_PREFIX_PATH}/dashboards/fuel-type-list/*`,
    component: React.lazy(() => import('views/app-views/dashboards/fuel-type'))
  },
  {
    key: routeKeys.BodyTypeKey,
    path: `${APP_PREFIX_PATH}/dashboards/body-type-list/*`,
    component: React.lazy(() => import('views/app-views/dashboards/body-type'))
  },
  {
    key: routeKeys.VehicleTypeKey,
    path: `${APP_PREFIX_PATH}/dashboards/vehicle-type-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/vehicle-type')
    )
  },
  {
    key: routeKeys.ColorKey,
    path: `${APP_PREFIX_PATH}/dashboards/color-list/*`,
    component: React.lazy(() => import('views/app-views/dashboards/color'))
  },
  {
    key: routeKeys.VersionKey,
    path: `${APP_PREFIX_PATH}/dashboards/version-list/*`,
    component: React.lazy(() => import('views/app-views/dashboards/version'))
  },
  {
    key: routeKeys.QuestionCategoryKey,
    path: `${APP_PREFIX_PATH}/dashboards/question-category-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/question-category')
    )
  },
  {
    key: routeKeys.InventoryQuestionKey,
    path: `${APP_PREFIX_PATH}/dashboards/inventory-question-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/inventory-question')
    )
  },
  {
    key: routeKeys.InventoryKey,
    path: `${APP_PREFIX_PATH}/dashboards/inventory-list/*`,
    component: React.lazy(() => import('views/app-views/dashboards/inventory'))
  },
  {
    key: routeKeys.StateKey,
    path: `${APP_PREFIX_PATH}/dashboards/state-list/*`,
    component: React.lazy(() => import('views/app-views/dashboards/state'))
  },
  {
    key: routeKeys.CityKey,
    path: `${APP_PREFIX_PATH}/dashboards/city-list/*`,
    component: React.lazy(() => import('views/app-views/dashboards/city'))
  },
  {
    key: routeKeys.CustomerKey,
    path: `${APP_PREFIX_PATH}/dashboards/customer-list/*`,
    component: React.lazy(() => import('views/app-views/dashboards/customer'))
  },
  {
    key: routeKeys.MainClientKey,
    path: `${APP_PREFIX_PATH}/dashboards/main-client-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/main-client')
    )
  },
  {
    key: routeKeys.ClientKey,
    path: `${APP_PREFIX_PATH}/dashboards/client-list/*`,
    component: React.lazy(() => import('views/app-views/dashboards/client'))
  },
  {
    key: routeKeys.ServicePriceKey,
    path: `${APP_PREFIX_PATH}/dashboards/service-price-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/service-price')
    )
  },
  {
    key: routeKeys.imageTypeKey,
    path: `${APP_PREFIX_PATH}/dashboards/image-types-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/image-type')
    )
  },
  {
    key: routeKeys.RcDetailsKey,
    path: `${APP_PREFIX_PATH}/dashboards/rc-details-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/rc-details')
    )
  },
  {
    key: routeKeys.SettingsKey,
    path: `${APP_PREFIX_PATH}/dashboards/settings-list/*`,
    component: React.lazy(() => import('views/app-views/dashboards/settings'))
  },
  // {
  //   key: routeKeys.InventoryAgentKey,
  //   path: `${APP_PREFIX_PATH}/dashboards/inventory-agent-list/*`,
  //   component: React.lazy(() =>
  //     import('views/app-views/dashboards/inventory-agent')
  //   )
  // },
  {
    key: routeKeys.InventoryOperationsKey,
    path: `${APP_PREFIX_PATH}/dashboards/inventory-operations-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/inventory-operations')
    )
  },
  {
    key: routeKeys.InventoryQcKey,
    path: `${APP_PREFIX_PATH}/dashboards/inventory-qc-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/inventory-qc')
    )
  },
  {
    key: routeKeys.InventoryPricingKey,
    path: `${APP_PREFIX_PATH}/dashboards/inventory-pricing-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/inventory-pricing')
    )
  },
  {
    key: routeKeys.InvoiceKey,
    path: `${APP_PREFIX_PATH}/dashboards/invoice-list/*`,
    component: React.lazy(() => import('views/app-views/dashboards/invoice'))
  },
  {
    key: routeKeys.ThirdPartyKey,
    path: `${APP_PREFIX_PATH}/dashboards/third-party-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/third-party')
    )
  },
  {
    key: routeKeys.ThirdPartyEmployeeKey,
    path: `${APP_PREFIX_PATH}/dashboards/third-party-employee-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/third-party-employee')
    )
  },
  {
    key: routeKeys.ClientExecutiveKey,
    path: `${APP_PREFIX_PATH}/dashboards/client-executive-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/client-executive')
    )
  },
  {
    key: routeKeys.RegionKey,
    path: `${APP_PREFIX_PATH}/dashboards/region-list/*`,
    component: React.lazy(() => import('views/app-views/dashboards/region'))
  },
  {
    key: routeKeys.TransactionKey,
    path: `${APP_PREFIX_PATH}/dashboards/transaction-list/*`,
    component: React.lazy(() =>
      import('views/app-views/dashboards/transaction')
    )
  },
  {
    key: routeKeys.BillsKey,
    path: `${APP_PREFIX_PATH}/dashboards/bills-list/*`,
    component: React.lazy(() => import('views/app-views/dashboards/bills'))
  }
];
