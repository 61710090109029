import React, { useContext, useState } from "react";
import ReportGrid from "./ReportGrid";
import VehicleDetails from "./VehicleDetails";
import styles from "./styles/CarReport.module.css";
import useWindowWidth from "components/useWindowWidth";
import ReactPlayer from "react-player";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VideoModal from "./VideoModal";
import { ModalContext } from "..";

const CarReport = ({
  pdfLink,
  imageLink,
  details,
  videoLinks,
  categoriesArray,
  clientName,
  scores,
  inventoryData,
  interiorLink,
  exteriorLink,
  inspectionVideo
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredTwo, setIsHoveredTwo] = useState(false);
  const [className, setClassName] = useState(styles.actionIcon);
  const [playing, setPlaying] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState("");
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [videoForModal, setVideoForModal] = useState("")

  const width = useWindowWidth();
  const showVideo = videoLinks.some(link => link.url !== undefined);


  const {
    editAccess
   } = useContext(ModalContext);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const arrowImage =
    "/img/report/pdf-download.svg";

  const handleMouseEnter = () => {
    setIsHovered(true);
    setClassName(styles.hoveredIcon); // Change class name on hover
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setClassName(styles.actionIcon); // Restore class name on mouse leave
  };

  const handleMouseEnterTwo = () => {
    setIsHoveredTwo(true);
    setClassName(styles.hoveredIcon); // Change class name on hover
  };

  const handleMouseLeaveTwo = () => {
    setIsHoveredTwo(false);
    setClassName(styles.actionIcon); // Restore class name on mouse leave
  };

  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank"; // Open the link in a new tab
    link.download = "video.mp4"; // Set the desired file name
    document.body.appendChild(link);

    // Simulate a click event to open the link in a new tab
    const event = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    link.dispatchEvent(event);

    // Remove the link from the document after some delay to ensure the download is triggered
    setTimeout(() => {
      document.body.removeChild(link);
    }, 100);
  };

  const handlePdfDownload = () => {
    const link = document.createElement("a");
    link.href = pdfLink;
    link.target = "_blank"; // Open the PDF in a new tab
    link.download = "inventory-report.pdf";
    document.body.appendChild(link);

    // Simulate a click event to open the PDF in a new tab
    const event = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    link.dispatchEvent(event);

    // Remove the link from the document after some delay to ensure the download is triggered
    setTimeout(() => {
      document.body.removeChild(link);
    }, 100);
  };

  const handleImageDownload = () => {
    const link = document.createElement("a");
    link.href = imageLink;
    link.download = "images.zip"; // Specify the filename for the downloaded file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="video-outer">
      <main className={styles.container}>
        <section className={styles.leftColumn}>
          <div className={styles.reportContainer}>
            {inspectionVideo && (
            <Slider {...settings}>
              {videoLinks.map((url) => (
                <div className={styles.imageContainer}>
                  <div className={styles.imageWrapper}>
                    {editAccess && (
                    <div
                      className={styles.editButton}
                      onClick={() => {
                        setVideoForModal(url.name)
                        setOverlayVisible(true)}}
                    >
                      Edit
                    </div>
                    )}
                    {videoForModal == url.name && (
                    <VideoModal
                      overlayVisible={overlayVisible}
                      setOverlayVisible={setOverlayVisible}
                      item = {url}
                      videoLinks = {videoLinks}
                      interiorLink = {interiorLink}
                      exteriorLink = {exteriorLink}
                    />
                  )}
                    <div className={styles.mainImage}>
                      <ReactPlayer
                        playing={playing && selectedVideo == url.name}
                        url={url.link}
                        controls
                        width="100%"
                        height="100%"
                      />
                    </div>

                    <div
                      // onClick={() => {
                      //   setPlaying(!playing);
                      //   setSelectedVideo(url.name);
                      // }}
                      className={styles.overlayContent}
                    >
                      <img
                        loading="lazy"
                        src="/img/report/play-button.svg"
                        className={styles.logo}
                        alt="Company logo"
                        onClick={() => {
                          setPlaying(!playing);
                          setSelectedVideo(url.name);
                        }}
                      />
                    </div>

                  </div>

                  <div className={styles.statusIndicator}>
                    <img
                      loading="lazy"
                      onClick={() => handleDownload(url.link)}
                      src={
                        width > 480
                          ? "/img/report/slider-download.svg"
                          : "/report-img/mobile-download.svg"
                      }
                      className={styles.statusIcon}
                      alt="Status indicator"
                    />
                  </div>
                </div>
              ))}
            </Slider>
)}
            <div className={styles.actionButtons}>
              <button
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className={styles.actionButton}
                data-aos="zoom-in-up"
                onClick={handlePdfDownload}
              >
                <img
                  loading="lazy"
                  src={
                    !isHovered
                      ? arrowImage
                      : "/report-img/button-arrow-green.svg"
                  }
                  className={className}
                  alt=""
                />
                <span className={styles.actionText}>Download PDF</span>
              </button>
              <button
                className={styles.actionButton}
                onMouseEnter={handleMouseEnterTwo}
                onMouseLeave={handleMouseLeaveTwo}
                data-aos="zoom-in-up"
                onClick={handleImageDownload}
              >
                <img
                  loading="lazy"
                  src={
                    !isHoveredTwo
                      ? arrowImage
                      : "/report-img/button-arrow-green.svg"
                  }
                  className={styles.actionIcon}
                  alt=""
                />
                <span className={styles.actionText}>Download Images</span>
              </button>
            </div>
            <ReportGrid categoriesArray={categoriesArray} scores={scores} />
          </div>
        </section>
        <VehicleDetails
          details={details}
          clientName={clientName}
          inventoryData={inventoryData}
        />
      </main>
    </div>
  );
};

export default CarReport;
