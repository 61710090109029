import React from 'react';
import styles from './CarInspection.module.css';

const InspectionLocation = ({location}) => {
  return (
    <div className={styles.inspectionLocation}>
      <div className={styles.locationHeader}>
        <img src="/img/report/location-icon.svg" alt="Location" className={styles.locationIcon} />
        <span className={styles.locationTitle} data-aos="fade-right" data-aos-duration="500" >Inspection Location</span>
      </div>
      <div data-aos="fade-down" data-aos-duration="500" className={styles.locationAddress}>
        {/* 3, 3rd Cross Rd, East of NGEF Layout, Kasturi Nagar, Bengaluru, Karnataka 560043, India. */}
        {location}
      </div>
    </div>
  );
};

export default InspectionLocation;